import React, { createContext, ReactNode, useContext, useEffect, useState } from 'react'
import { useNavigate, useLocation, Navigate } from 'react-router-dom'

import apiService, { CodeStatus } from 'src/services/ApiService'

interface AuthContextType {
    isAuthenticated: boolean
    validating: boolean
    validateCode: (code: string) => Promise<CodeStatus>
}

const AuthContext = createContext<AuthContextType>(null!)

const AuthProvider = ({ children }: { children: ReactNode }) => {
    const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false)
    const [validating, setValidating] = useState<boolean>(false)

    const validateCode = async (code: string): Promise<CodeStatus> => {
        setValidating(true)
        const status = await apiService.validateCode(code)
        setValidating(false)
        if (status === CodeStatus.success) {
            setIsAuthenticated(true)
        }
        return status
    }

    const value = { isAuthenticated, validating, validateCode }

    return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
}

export const useAuth = () => {
    return useContext(AuthContext)
}

export default AuthProvider