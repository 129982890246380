import React from 'react'

import slide1 from 'src/images/photos/first.jpg'
import slide2 from 'src/images/photos/second2.jpg'
import slide3 from 'src/images/photos/third.jpg'
import slide4 from 'src/images/photos/fourth3.jpg'

const Story = () => {
    return (

        <section className='story-section section-padding' id='story'>
            <div className='container'>
                <div className='row'>
                    <div className='col col-xs-12'>
                        <div className='section-title'>
                            <div className='vertical-line'><span><i className='fi flaticon-two' /></span></div>
                            <h2>My dva</h2>
                        </div>
                    </div>
                </div>

                <div className='row'>
                    <div className='col col-xs-12'>
                        <div className='story-timeline'>
                            <div className='row'>
                                <div className='col col-md-6'>
                                    <div className='story-text right-align-text'>
                                        <h3>První "seznámení"</h3>
                                        <span className='date'>19. března 2020</span>
                                        <p className='justify'>Naše první seznámení proběhlo dvěma taženími doprava na nejmenované aplikaci
                                            a po zjištění vzájemné shody začalo lustrování protějšku. 😊 &nbsp; Nevěstu zajímala
                                            výška, příjmení a pěkné nohy, ženich zase přemýšlel, jak asi vypadá nevěsta v plavkách 
                                            a jestli nemá vadu řeči.
                                            Po pár propsaných dnech bylo rozhodnuto, že dojde k prvnímu osobnímu setkání. 😊
                                        </p>
                                    </div>
                                </div>
                                <div className='col col-md-6'>
                                    <div className='img-holder'>
                                        <img src={slide1} className='img img-responsive' />
                                    </div>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col col-md-6'>
                                    <div className='img-holder right-align-text'>
                                        <img src={slide2} className='img img-responsive' />
                                        {/* <img src={slide1} className='img img-responsive' /> */}
                                    </div>
                                </div>
                                <div className='col col-md-6 text-holder'>
                                    <span className='heart'>
                                        <i className='fa fa-heart' />
                                    </span>
                                    <div className='story-text'>
                                        <h3>První rande</h3>
                                        <span className='date'>25. března 2020</span>
                                        <p className='justify'>Před barákem stála nablýskaná Mazda, vedle ní frajer ve vestičce
                                            čekající na svou vyvolenou. Když byly odhozeny roušky, nevěsta se
                                            prokázala pevným stiskem ruky a neráčkující mluvou, mohlo se nasednout
                                            do auta a vyrazit. Jak je ženichovým zvykem,
                                            vytasil 2 lahvičky vína a věděl, že má nevěstu jasnou. 😊 Ačkoliv po
                                            chvíli ve vestičce začal klepat kosu a fňukal, že je mu zima, i
                                            tak byl nevěstě sympatický a věděla, že frajera znovu ráda uvidí! 😊
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col col-md-6 text-holder right-heart'>
                                    <span className='heart'>
                                        <i className='fa fa-heart' />
                                    </span>
                                    <div className='story-text right-align-text'>
                                        <h3>Žádost</h3>
                                        <span className='date'>28. září 2021</span>
                                        <p className='justify'>Milé děti, nic netušící budoucí nevěsta vyrazila v doprovodu svého 
                                        budoucího muže do 
                                        města na masáž, domnívajíc se, že jde o její narozeninový dárek. Na pátou bylo naplánované 
                                        kino v Lucerně,
                                        nic neobvyklého a nevěsta neměla ani ponětí, co se chystá. Když dorazili do kina, začalo 
                                        jí být divné,
                                        že jsou v sále sami.<br/>
                                        Zhasla se světla a ihned při prvních tónech poznala, že tento film je pouze a jen pro ní. 
                                        Po skončení
                                        promítání se oba vydali ven z Lucerny, kde již čekal odvoz na jejich oblíbené místo, Vyšehrad.<br/>
                                        Po vystoupení z vozu a posilněni proseccem nevěsta zpozorovala papírové srdíčko zavěšené 
                                        na stromě a 
                                        když dorazili blíže, uviděla nápis M&T. Ihned spatřila další a další srdíčka a společně následovali
                                         srdíčkovou cestu až na jejich oblíbené místečko. Zde již čekal svědek a ladným, minutovým pohybem 
                                         předal ženichovi malou krabičku. Přelezli zeď, slovo dalo slovo, zazvonil zvonec a long 
                                         story short,
                                         řekla ANO a vůbec si to nepamatuje... 😀
                                        </p>
                                    </div>
                                </div>
                                <div className='col col-md-6'>
                                    <div className='img-holder video-holder'>
                                        <img src={slide3} className='img img-responsive' />
                                        <a href='https://www.youtube.com/embed/LE57oircQt4?autoplay=1' data-type='iframe' className='video-play-btn'>
                                            <i className='fa fa-play' />
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col col-md-6'>
                                    <div className='img-holder right-align-text'>
                                        <img src={slide4} className='img img-responsive' />
                                        {/* <img src={slide1} className='img img-responsive' /> */}
                                    </div>

                                </div>
                                <div className='col col-md-6 text-holder'>
                                    <span className='heart'>
                                        <i className='fa fa-heart' />
                                    </span>
                                    <div className='story-text'>
                                        <h3>Svatba</h3>
                                        <span className='date'>3. června 2022</span>
                                        <p className='justify'>Termín je jasný a jak svatba proběhne, to už záleží
                                            na vás všech zúčastněných. Na nové zážitky se oba moc
                                            těšíme a budeme rádi, když si to společně všichni užijeme! 😊
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

    )
}

export default Story
