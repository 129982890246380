import React, { useEffect } from 'react'
import { useLocation, Navigate, useNavigate } from 'react-router-dom'
import { useAuth } from 'src/providers/AuthProvider'
import { CodeStatus } from 'src/services/ApiService'

import 'src/styles/secret.scss'
import Countdown from './Components/Countdown'
import Form from './Components/Form'
import Gallery from './Components/Gallery'
import GroomBride from './Components/GroomBride'
import Harmonogram from './Components/Harmonogram'
import Header from './Components/Header'
import Hero from './Components/Hero'
import Info from './Components/Info'
import Photos from './Components/Photos'
import Results from './Components/Results'
import Story from './Components/Story'

const SecretHomepage = () => {
    const auth = useAuth()
    const navigate = useNavigate()

    // useEffect(() => {
    //     (async () => {
    //         const code = localStorage.getItem('code')
    //         if (code) {
    //             const status = await auth.validateCode(code)
    //             if (status === CodeStatus.error) {
    //                 navigate('/')
    //             }
    //         } else {
    //             navigate('/')
    //         }
    //     })()

    // }, [])

    //   if (auth.isAuthenticated) {
    return (
        <>
            <div className="preloader">
                <div className="inner">
                    <span className="icon">
                        <i className="fi flaticon-two" />
                    </span>
                </div>
            </div>

            <Hero />
            <Header />
            <Photos />
            <GroomBride />
            <Countdown />
            <Story />
            <Harmonogram />
            <Gallery />
            <Info />
            <Form />
        </>
    )
    //   }
    //   return null;
}

export default SecretHomepage
