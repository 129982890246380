import React from 'react'

import slide1 from 'src/images/photos/tom.jpg'
import slide2 from 'src/images/photos/maky.jpg'

const Hero = () => {
    return (

        <section className='wedding-couple-section section-padding' id='couple'>
            <div className='container'>
                <div className='row'>
                    <div className='col col-xs-12'>
                        <div className='gb groom'>
                            <div className='img-holder wow fadeInLeftSlow'>
                                <img src={slide1} alt='' />
                            </div>
                            <div className='details'>
                                <div className='details-inner'>
                                    <h3>Ženich</h3>
                                    <h4>- podle svědkyně Pavly</h4>
                                    <p>Jako svědkyně nevěsty jsem dostala za úkol představit Vám ženicha,
                                        snad byste nečekali něco jiného. <br/>
                                        
                                        Ženichem je Tom, 185cm vysoký, 
                                        štíhlý, ale přitom svalnatý, tmavovlasý prototyp
                                        muže, kterého by si téměř každá žena přála mít po svém boku.<br/>

                                        Jak jsem zatím Toma stihla poznat, tak vím, že když chce, 
                                        umí být milý, když nechce, umí si pěkně rýpnout,
                                        je přátelský, není žádný stydlín, rozhodně nemůže chybět u žádné srandy, kterou nikdy nezkazí
                                        a na každé párty zpravidla zhasíná a zavírá jako poslední.<br/>

                                        Trošku se bojím, aby k "oltáři" nenakráčel místo obleku v kostýmu banánu, 
                                        protože "se přece s klukama vsadil".
                                        Ale to jen tak pro odlehčení.<br/>

                                        Tom umí být zodpovědný, je pracovitý, šikovný, inteligentní, pozorný,
                                         dokáže druhému naslouchat a co vím, tak
                                        Markét plní přání ještě dřív, než je vůbec stačí vyslovit.<br/>

                                        Má spoustu koníčků - krásně hraje na kytaru a zpívá, baví ho práce se dřevem, 
                                        takže je i kutil, zároveň hraje v divadle,
                                        do posilovny si také najde cestu, tudíž ani sport mu není cizí. <br/>

                                        V křížovkářském slovníku jsem našla, že všestranně nadaný
                                        člověk na šest písmen je Genius, tak co víc si Markét mohla přát.</p>
                                    {/* <span className='signature'>Tom</span> */}
                                </div>
                            </div>
                        </div>
                        <div className='gb bride poem'>
                            <div className='details'>
                                <div className='details-inner'>
                                    <h3>Nevěsta</h3>
                                    <h4>- podle svědka Petra</h4>
                                    <div className='wrapper'>
                                        <p>Blonďatý vlas jak slunce žhnoucí<br />
                                            a její krása dechberoucí,<br />
                                            jak květina co rozkvétá,<br />
                                            to je Markéta!<br /><br />

                                            Modré oči, krásná pleť,<br />
                                            tu holku chceš mít hned a teď!<br />
                                            Jak nejvzácnější kometa,<br />
                                            to je Markéta!<br /><br />

                                            Je chytrá, je vtipná až do nebe,<br />
                                            je holkou co chceš mít u sebe,<br />
                                            je jak nejkrásnější planeta,<br />
                                            to je Markéta!<br /><br />
                                        </p>

                                        <p>Je poklidná i divoká,<br />
                                            ta hned ti padne do oka,<br />
                                            je roztomile ujetá,<br />
                                            to je Markéta!<br /><br />

                                            Pere, žehlí, vařit umí<br />
                                            a i když Tomáš blbě čumí,<br />
                                            vytírá i zametá,<br />
                                            to je Markéta!<br /><br />

                                            Je možné, že někdy nervy ztrácí,<br />
                                            po propité noci trošku zvrací<br />
                                            přesto vždy byla a bude ta,<br />
                                            naše Markéta!<br /><br />
                                        </p>
                                    </div>
                                    <p className='last'>Přejme všichni Markétě,<br />
                                        ať se má nejlépe na světě,<br />
                                        ať nechá se životem unášet,<br />
                                        ať miluje svého Tomáše!</p>
                                    {/* <span className='signature'>Maky</span> */}
                                </div>
                            </div>
                            <div className='img-holder wow fadeInRightSlow'>
                                <img src={slide2} alt='' />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

    )
}

export default Hero
