import React from 'react'

import slide1 from 'src/images/sliders/first.jpeg'
import slide2 from 'src/images/sliders/second.jpeg'

const Hero = () => {
    return (
        <section className="hero" id="home">
            <div className="static-hero" />
            {/* <div className='hero-slider hero-slider-s1'>
                <div className='slide-item'>
                    <img src={slide1} className='slider-bg' />
                </div>

                <div className='slide-item'>
                    <img src={slide2} className='slider-bg' />
                </div>
            </div> */}
            <div id="spirit-header" className="spirit-header">
                <canvas id="spirit-canvas" />
            </div>
            <div className="wedding-announcement">
                <div className="couple-name-merried-text">
                    <h2 className="wow slideInUp" data-wow-duration="1s">
                        Maky &amp;Tom
                    </h2>
                    <div className="married-text wow fadeIn" data-wow-delay="1s">
                        <h4 className="">
                            <span className=" wow fadeInUp" data-wow-delay="1.05s">
                                V
                            </span>
                            <span className=" wow fadeInUp" data-wow-delay="1.10s">
                                z
                            </span>
                            <span className=" wow fadeInUp" data-wow-delay="1.15s">
                                a
                            </span>
                            <span className=" wow fadeInUp" data-wow-delay="1.20s">
                                l
                            </span>
                            <span className=" wow fadeInUp" data-wow-delay="1.25s">
                                i
                            </span>
                            <span>&nbsp;</span>
                            <span className=" wow fadeInUp" data-wow-delay="1.30s">
                                j
                            </span>
                            <span className=" wow fadeInUp" data-wow-delay="1.35s">
                                s
                            </span>
                            <span className=" wow fadeInUp" data-wow-delay="1.40s">
                                m
                            </span>
                            <span className=" wow fadeInUp" data-wow-delay="1.45s">
                                e
                            </span>
                            <span>&nbsp;</span>
                            <span className=" wow fadeInUp" data-wow-delay="1.50s">
                                s
                            </span>
                            <span className=" wow fadeInUp" data-wow-delay="1.55s">
                                e
                            </span>
                        </h4>
                    </div>
                </div>

                <div className="save-the-date">
                    <h4>Naše datum</h4>
                    <span className="date">3.6.2022</span>
                </div>
            </div>
        </section>
    )
}

export default Hero
