import React from 'react'

import slide1 from 'src/styles/images/slider/slide-1.jpg'
import slide2 from 'src/styles/images/slider/slide-2.jpg'
import logo from 'src/images/logo.png'

const Header = () => {
    return (
        <header id="header" className="site-header header-style-1">
            <nav className="navigation navbar navbar-default">
                <div className="container">
                    <div className="navbar-header">
                        <button type="button" className="open-btn">
                            <span className="sr-only">Toggle navigation</span>
                            <span className="icon-bar" />
                            <span className="icon-bar" />
                            <span className="icon-bar" />
                        </button>
                        <div className="couple-logo">
                            {/* <h1><a href='#home'>M <i className='fi flaticon-shape-1' /> T</a></h1> */}
                            <h1>
                                <a href="#home">
                                    <img src={logo} />
                                </a>
                            </h1>
                        </div>
                    </div>
                    <div
                        id="navbar"
                        className="navbar-collapse collapse navbar-right navigation-holder"
                    >
                        <button className="close-navbar">
                            <i className="fa fa-close" />
                        </button>
                        <ul className="nav navbar-nav">
                            <li>
                                <a className="new" href="#photos">
                                    Fotky
                                </a>
                            </li>
                            <li>
                                <a href="#home">Domů</a>
                            </li>
                            <li>
                                <a href="#couple">My</a>
                            </li>
                            <li>
                                <a href="#story">Příběh</a>
                            </li>
                            <li>
                                <a href="#harmonogram">Hermonogram</a>
                            </li>
                            <li>
                                <a href="#gallery">Galerie</a>
                            </li>
                            <li>
                                <a href="#info">Informace</a>
                            </li>
                            <li>
                                <a href="#rsvp">Příjezd</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>
        </header>
    )
}

export default Header
