import React, { useEffect, useState, FormEvent } from 'react'
import androidImage from 'src/assets/android.png'
import iosImage from 'src/assets/ios.png'
import logo from 'src/images/logo.png'
import 'src/styles/default.sass'
import { getPlatform, Platform } from 'src/utils'
import axios from 'axios'
import { useAuth } from 'src/providers/AuthProvider'
import { CodeStatus } from 'src/services/ApiService'
import { useNavigate } from 'react-router-dom'
import { Guest } from './Secret/Components/Form'

import moment from 'moment'

const Guests = () => {

    const [guests, setGuests] = useState<Guest[]>([])

    useEffect(() => {
        (async () => {
            try {
                const results = await axios.get('https://mtadventures-api.tesseractparadox.com/guests')
                setGuests(results.data)
            } catch (error) {

            }
        })()
    }, [])

    return (
        <div className='guests'>
            <div className='container'>
                <table className='table table-hover'>
                    <thead>
                        <tr>
                            <th>Datum</th>
                            <th>Jméno</th>
                            <th>E-mail</th>
                            <th>Plus 1</th>
                            <th>Plus 1 jméno</th>
                            <th>Přespání</th>
                            <th>Zpráva</th>
                        </tr>
                    </thead>
                    <tbody>
                        {guests.map((guest, index) => {
                            return (
                                <tr key={index}>
                                    <td>{moment(guest.createdAt).format('DD.MM.YYYY')}</td>
                                    <td>{guest.name}</td>
                                    <td>{guest.email}</td>
                                    <td>{guest.plusOne ? 'Ano' : 'Ne'}</td>
                                    <td>{guest.plusOneName}</td>
                                    <td>{guest.sleepover ? 'Ano' : 'Ne'}</td>
                                    <td>{guest.message}</td>
                                </tr>
                            )
                        })}
                        
                    </tbody>
                </table>

            </div>
        </div>

    )
}

export default Guests
