import React, { useState } from 'react'

import slide1 from 'src/images/photos/30.jpg'
import slide2 from 'src/images/sliders/second.jpeg'

import axios from 'axios'

export interface Guest {
    name: string
    email: string
    plusOne: boolean
    plusOneName: string
    sleepover: boolean
    message: string
    createdAt?: string
}

const Form = () => {

    const [name, setName] = useState<string>('')
    const [email, setEmail] = useState<string>('')
    const [plusOne, setPlusOne] = useState<string>('')
    const [plusOneName, setPlusOneName] = useState<string>('')
    const [sleepover, setSleepover] = useState<string>('')
    const [message, setMessage] = useState<string>('')
    const [success, setSuccess] = useState<boolean>(false)

    const handleChangeName = (event) => {
        setName(event.target.value)
    }

    const handleChangePlusOneName = (event) => {
        setPlusOneName(event.target.value)
    }

    const handleChangeEmail = (event) => {
        setEmail(event.target.value)
    }

    const handleChangeMessage = (event) => {
        setMessage(event.target.value)
    }

    const handleChangeSleepover = (event) => {
        setSleepover(event.target.value)
    }

    const handleChangePlusOne = (event) => {
        setPlusOne(event.target.value)
    }

    const handleSubmit = async (event) => {
        event.preventDefault()
        const data: Guest = {
            name,
            email,
            plusOne: plusOne === 'yes',
            plusOneName,
            sleepover: sleepover === 'yes',
            message
        }
        try {
            await axios.post('https://mtadventures-api.tesseractparadox.com/guest', data)
            setSuccess(true)
        } catch (error) {

        }
    }


    return (

        <section className='rsvp-section section-padding parallax' data-bg-image={slide1} data-speed='7' id='rsvp'>
            <div className='container'>
                <div className='row'>
                    <div className='col col-xs-12'>
                        <div className='section-title-white'>
                            <div className='vertical-line'><span><i className='fi flaticon-two' /></span></div>
                            <h2>Budeš tam s námi?</h2>
                        </div>
                    </div>
                </div>

                {!success && <div className='row content'>
                    <div className='col col-lg-8 col-lg-offset-2 col-md-10 col-md-offset-1'>
                        <p>Vyplň, prosím, co nejdříve. Děkujeme!</p>
                        <form className='form row' onSubmit={handleSubmit}>
                            <div className='col col-sm-6'>
                                <input type='text' value={name} className='form-control'
                                    onChange={handleChangeName} placeholder='Tvoje jméno*' required={true} />
                            </div>
                            <div className='col col-sm-6'>
                                <input type='email' value={email}
                                    onChange={handleChangeEmail} className='form-control' placeholder='Tvůj email*' required={true} />
                            </div>
                            <div className='col col-sm-6'>
                                <select className='form-control' required={true} value={plusOne} onChange={handleChangePlusOne} >
                                    <option disabled={true} value='' >Bereš někoho s sebou?</option>
                                    <option value='yes'>Ano</option>
                                    <option value='no'>Ne</option>
                                </select>
                                {plusOne === 'yes' && <input type='text' value={plusOneName} className='form-control plus-one-name'
                                    onChange={handleChangePlusOneName} placeholder='Jméno, koho bereš s sebou*' required={true} />
    }
                            </div>
                            <div className='col col-sm-6'>
                                <select className='form-control' required={true} value={sleepover} onChange={handleChangeSleepover}>
                                    <option disabled={true} value=''>Budeš chtít přespat?</option>
                                    <option value='yes'>Ano</option>
                                    <option value='no'>Ne</option>
                                </select>
                            </div>
                            <div className='col col-sm-12'>
                                <textarea className='form-control' value={message}
                                    onChange={handleChangeMessage} placeholder='Tvoje zpráva pro nás' />
                            </div>
                            <div className='col col-sm-12 submit-btn'>
                                <button type='submit' className='submit'>Odeslat</button>
                                {/* <span id='loader'><i className='fa fa-refresh fa-spin fa-3x fa-fw' /></span> */}
                            </div>
                        </form>
                    </div>
                </div>}
                {success && <div className='col col-md-12 success-error-message'>
                    <div id='success'>Děkujeme za vyplnění formuláře a budeme se těšit!</div>
                </div>}
            </div>
        </section>

    )
}

export default Form
