import React from "react";
import ReactDOM from "react-dom";
import "./index.sass";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import reportWebVitals from "./reportWebVitals";
import SecretHomepage from "./pages/Secret/Homepage";
import Homepage from "./pages/Homepage";
import NotFound from "./pages/NotFound";
import AuthProvider from "./providers/AuthProvider";
import Guests from "./pages/Guests";

ReactDOM.render(
  <React.StrictMode>
    <AuthProvider>
      <BrowserRouter>
        <Routes>
          <Route path="/secret" element={<SecretHomepage />} />
          <Route path="/" element={<SecretHomepage />} />
          <Route path="/hoste" element={<Guests />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </BrowserRouter>
    </AuthProvider>
  </React.StrictMode>,
  document.getElementById("page-wrapper")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
