import React from 'react'

import slide1 from 'src/images/sliders/first.jpeg'
import slide2 from 'src/images/sliders/second.jpeg'

const Harmonogram = () => {

    const events = [{
        title: 'Příjezd hostů',
        time: '12:00'
    }, {
        title: 'Obřad',
        time: '13:00'
    }, {
        title: 'Společné focení',
        time: '13:30'
    }, {
        title: 'Oběd',
        time: '14:30'
    }, {
        title: 'Focení novomanželů',
        time: '16:00'
    }, {
        title: 'Krájení dortu',
        time: '17:00'
    }, {
        title: 'Stříhání stuhy',
        time: '18:00'
    }, {
        title: 'První tanec',
        time: '18:30'
    }, {
        title: 'Večerní raut',
        time: '19:00'
    }, {
        title: 'Zábava a hry',
        time: '20:00'
    }, {
        title: 'Prskavky',
        time: '22:00'
    }]
    return (

        <section className='events-section section-padding' id='harmonogram'>
            <div className='container'>
                <div className='row'>
                    <div className='col col-xs-12'>
                        <div className='section-title'>
                            <div className='vertical-line'><span><i className='fi flaticon-two' /></span></div>
                            <h2>Hermonogram</h2>
                        </div>
                    </div>
                </div>

                <div className='row'>
                    <div className='col col-xs-12'>
                        {events.map(event => {
                            return (
                                <div key={event.title} className='event'>
                                    <div className='details left'>
                                        <p>{event.time}</p>
                                    </div>
                                    <div className='details right'>
                                        <h3>{event.title}</h3>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </div>
            </div>
        </section >

    )
}

export default Harmonogram
