import React from 'react'

import slide1 from 'src/images/photos/1.jpg'
import slide2 from 'src/images/photos/10.jpg'
import slide3 from 'src/images/photos/24.jpg'
import slide4 from 'src/images/photos/4.jpg'
import slide5 from 'src/images/photos/26.jpg'
import slide6 from 'src/images/photos/second.jpg'
import slide7 from 'src/images/photos/18.jpg'
import slide8 from 'src/images/photos/8.jpg'
import slide9 from 'src/images/photos/9.jpg'
import slide10 from 'src/images/photos/5.jpg'
import slide11 from 'src/images/photos/25.jpg'
import slide12 from 'src/images/photos/14.jpg'
import slide13 from 'src/images/photos/15.jpg'
import slide14 from 'src/images/photos/16.jpg'
import slide15 from 'src/images/photos/22.jpg'
import slide16 from 'src/images/photos/19.jpg'
import slide17 from 'src/images/photos/20.jpg'

const Gallery = () => {

    const photos = [
        slide1, slide12, slide3, 
        slide4, slide5, slide8, 
        slide6, slide7, slide9, 
        slide10, slide11, slide2, 
        slide13, slide14, slide15,
        slide16, slide17
    ]

    return (

        <section className='gallery-section section-padding' id='gallery'>
            <div className='container'>
                <div className='row'>
                    <div className='col col-xs-12'>
                        <div className='section-title'>
                            <div className='vertical-line'><span><i className='fi flaticon-two' /></span></div>
                            <h2>Naše fotky</h2>
                        </div>
                    </div>
                </div>

                <div className='row'>
                    <div className='col col-xs-12 sortable-gallery'>
                        {/* <div className='gallery-filters'>
                            <ul>
                                <li><a data-filter='*' href='#' className='current'>Všechny</a></li>
                                <li><a data-filter='.bride' href='#'>Nevěsta</a></li>
                                <li><a data-filter='.groom' href='#'>Ženich</a></li>
                                <li><a data-filter='.all' href='#'>Společné</a></li>
                            </ul>
                        </div> */}

                        <div className='gallery-container gallery-fancybox masonry-gallery'>
                            {photos.map((photo, index) => {
                                return (
                                    <div key={index} className='grid bride'>
                                        <a href={photo} className='fancybox' data-fancybox-group='gall-1'>
                                            <img src={photo} className='img img-responsive' />
                                        </a>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </div>
            </div>
        </section>

    )
}

export default Gallery
