import React, { useEffect, useState } from 'react'

import main from 'src/images/photos/main.jpeg'

interface Score {
    score: number
    playerName: string
}

const Photos = () => {
    return (
        <section className="story-section section-padding" id="photos">
            <div className="container">
                <div className="row">
                    <div className="col col-xs-12">
                        <div className="section-title">
                            <div className="vertical-line">
                                <span>
                                    <i className="fi flaticon-two" />
                                </span>
                            </div>
                            <h2>Fotky &amp; Video</h2>
                        </div>
                    </div>
                </div>

                <div className="row content">
                    <div className="img-holder video-holder">
                        <img src={main} className="img img-responsive" />
                        <a
                            href="https://www.youtube.com/embed/xXPZvwBe3qE?autoplay=1"
                            data-type="iframe"
                            className="video-play-btn"
                        >
                            <i className="fa fa-play" />
                        </a>
                    </div>
                    <ul className="photos">
                        <li>
                            <a href="https://youtu.be/nB8TDmasvik" target="_blank">
                                Proslovy
                            </a>
                        </li>
                        <li>
                            <a href="https://photos.app.goo.gl/KM5nk34xTmfAqC8SA" target="_blank">
                                Fotky ze svatby
                            </a>
                        </li>
                        <li>
                            <a href="https://photos.app.goo.gl/MPJRNJs3nJEF3tNn8" target="_blank">
                                Fotokoutek
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </section>
    )
}

export default Photos
