import React from 'react'

import slide1 from 'src/images/photos/30.jpg'
import slide2 from 'src/images/sliders/second.jpeg'

const Countdown = () => {
    return (
        <section
            className="count-down-section section-padding parallax"
            data-bg-image={slide1}
            data-speed="7"
        >
            <div className="container">
                <div className="row">
                    <div className="col col-md-12">
                        <h2>Ruku v ruce jsme se vydali za dobrodružstvím</h2>
                    </div>
                    {/* <div className='col col-md-7 col-md-offset-1'>
                        <div className='count-down-clock'>
                            <div id='clock' />
                        </div>
                    </div> */}
                </div>
            </div>
        </section>
    )
}

export default Countdown
