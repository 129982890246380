import React from 'react'

import map from 'src/images/map.png'

const Info = () => {
    return (

        <section className='getting-there-section section-padding' id='info'>
            <div className='container'>
                <div className='row'>
                    <div className='col col-xs-12'>
                        <div className='section-title-white'>
                            <div className='vertical-line'><span><i className='fi flaticon-two' /></span></div>
                            <h2>Informace</h2>
                        </div>
                    </div>
                </div>

                <div className='row content'>
                    <div className='col col-md-6'>

                        <img src={map} className='map' />
                    </div>
                    <div className='col col-md-6'>
                        <h3>Doprava</h3>
                        <p>Příjezd svatebčanů je směrem od Předbojského rybníka,
                            přes závoru kolem golfu na parkoviště, které je vyhrazeno pro svatby –
                            na mapce vyznačeno fialovou barvou. Parkování uvnitř hlavního dvora je
                            určeno pro hosty restaurace ke krátkodobému parkování a hostům nebude umožněno zde parkovat.</p>
                        <h3 className='mt-10'>Ubytování</h3>
                        <p>S ubytováním si nemusíte dělat starosti, postaráme se o něj my.
                            Jen bychom vás rádi informovali, že ubytování je možné až po
                            14. hodině a ráno musíme pokoje vyklidit do 10 hodin. Snídaně je zajištěna.</p>
                    </div>
                </div>
                {/* <div className='row content'>
                    <div className='col col-md-6'>
                        <h3>Doprava</h3>
                        <p>Příjezd svatebčanů je směrem od Předbojského rybníka,
                            přes závoru kolem golfu na parkoviště, které je vyhrazeno pro svatby –
                            na mapce vyznačeno fialovou barvou. Parkování uvnitř hlavního dvora je
                            určeno pro hosty restaurace ke krátkodobému parkování a hostům nebude umožněno zde parkovat</p>
                    </div>
                    <div className='col col-md-6'>
                        <h3>Ubytování</h3>
                        <p>Ubytování je možné až po 14:00.</p>
                    </div>
                </div> */}
            </div>
        </section>

    )
}

export default Info
