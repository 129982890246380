import axios from 'axios'

export enum CodeStatus { success, error }
class ApiService {
    private API_URL = 'https://mtadventures-api.tesseractparadox.com'

    validateCode = async (code: string): Promise<CodeStatus> => {
        try {
            await axios.post(`${this.API_URL}/validate-code/${code}`)
            return CodeStatus.success
        } catch (error) {
            return CodeStatus.error
        }
    }

    isAuthenticated = (): boolean => {
        return false
    }
}


const apiService = new ApiService()
export default apiService